<template>
  <div class="eventReport">
    <div class="title">
      <h2>Event Report</h2>
    </div>
    <div class="bgf0">
      <el-row type="flex">
        <el-col :span="24" style="padding:16px;">
          <el-card shadow="hover" class="pad30">
            <el-form v-model="eventReport" :inline="true">
              <el-row :gutter="24">
                <el-col :xs="24" :sm="3" :md="3" :lg="3" :xl="3">
                  <label class="labelName">eventName(s)</label>
                </el-col>
                <el-form-item label="EventName(s)" label-width="1.8rem">
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-input
                      v-model="eventReport.eventName"
                      placeholder="eventName: eg. 2,2_34_566"
                    ></el-input>
                  </el-col>
                </el-form-item>
              </el-row>
              <el-row :gutter="24">
                <el-col :xs="24" :sm="2" :md="2" :lg="2" :xl="2">
                  <label class="labelName">Offer(s)</label>
                </el-col>
                <el-form-item label="OfferId(s)" label-width="1.8rem">
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-input
                      v-model="eventReport.offerIds"
                      placeholder="offerIds: eg. 2206264 or 2206264,1567396"
                    ></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item>
                  <el-col :xs="1" :sm="1" :md="1" :lg="1" :xl="1">
                    <el-checkbox v-model="offerChecked"></el-checkbox>
                  </el-col>
                </el-form-item>
              </el-row>
              <el-row :gutter="24">
                <el-col :xs="24" :sm="2" :md="2" :lg="2" :xl="2">
                  <label class="labelName">Affiliate(s)</label>
                </el-col>
                <el-form-item label="AffiliateId(s)" label-width="2.08rem">
                  <el-col :xs="24" :sm="20" :md="20" :lg="20" :xl="20">
                    <group-select
                      v-model="eventReport.affiliateIds"
                      :data-source="affiliatesGroupList"
                      multiple
                      :loading="affiliateLoading"
                      clearable
                      reserve-keyword
                      filterable
                      placeholder="请选择"
                      style="width:4.14rem;"
                    />
                  </el-col>
                </el-form-item>
                <el-form-item>
                  <el-col :xs="1" :sm="1" :md="1" :lg="1" :xl="1">
                    <el-checkbox v-model="affiliateChecked"></el-checkbox>
                  </el-col>
                </el-form-item>
              </el-row>
              <el-row :gutter="24">
                <el-col :xs="24" :sm="2" :md="2" :lg="2" :xl="2">
                  <label class="labelName">Source(s)</label>
                </el-col>
                <el-form-item label="Source(s)" label-width="1.8rem">
                  <el-col :xs="24" :sm="20" :md="20" :lg="20" :xl="20">
                    <group-select
                      v-model="eventReport.sourceIds"
                      :data-source="sourcesList"
                      multiple
                      :loading="sourceLoading"
                      reserve-keyword
                      clearable
                      filterable
                      placeholder="请选择"
                      style="width:4.14rem;"
                    />
                  </el-col>
                </el-form-item>
                <el-form-item>
                  <el-col :xs="1" :sm="1" :md="1" :lg="1" :xl="1">
                    <el-checkbox v-model="sourceChecked"></el-checkbox>
                  </el-col>
                </el-form-item>
              </el-row>
              <el-row :gutter="24">
                <el-col :lg="2" :md="2" :xs="24" :sm="2" :xl="2">
                  <label class="labelName">Target(s)</label>
                </el-col>
                <el-form-item label="Package" label-width="1.8rem">
                  <el-col :lg="7" :md="20" :xs="24" :sm="12" :xl="12">
                    <el-input
                      v-model="eventReport.pkgList"
                      style="width:2.5rem;"
                      placeholder="eg. com.whatsapp,com.nutclean"
                    ></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item>
                  <el-col :lg="1" :md="1" :xs="1" :sm="1" :xl="1">
                    <el-checkbox v-model="packageChecked"></el-checkbox>
                  </el-col>
                </el-form-item>
                <el-form-item label="Countries" label-width="1.8rem">
                  <el-col :lg="7" :md="20" :xs="24" :sm="12" :xl="12">
                    <el-input
                      v-model="eventReport.countries"
                      style="width:2.5rem;"
                      placeholder="eg. HK,IN"
                    ></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item>
                  <el-col :lg="1" :md="1" :xs="1" :sm="1" :xl="1">
                    <el-checkbox v-model="countriesChecked"></el-checkbox>
                  </el-col>
                </el-form-item>
              </el-row>
              <el-row :gutter="24">
                <el-col :lg="2" :md="2" :xs="24" :sm="2" :xl="2">
                  <label class="labelName">Aff_sub(s)</label>
                </el-col>
                <el-form-item label="Aff_Sub" label-width="1.64rem">
                  <el-col :lg="8" :md="8" :xs="24" :sm="8" :xl="8">
                    <el-input
                      v-model="eventReport.affiliateSub"
                      style="width:2.8rem;"
                      placeholder="eg. carry_i"
                    ></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item>
                  <el-col :lg="1" :md="1" :xs="1" :sm="1" :xl="1">
                    <el-checkbox v-model="affiliateSubChecked"></el-checkbox>
                  </el-col>
                </el-form-item>
              </el-row>
              <el-row>
                <label class="labelName" style="margin-right:20px;">TimeZone</label>
                <el-form-item>
                  <el-select v-model="eventReport.timezone" style="width:420px;">
                    <el-option
                      v-for="item in timezoneOption"
                      :key="item.value"
                      :value="item.number"
                      :label="item.label"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-row>
              <el-row>
                <el-col :lg="3" :md="3" :xs="3" :sm="3" :xl="3">
                  <label class="labelName">Interval</label>
                </el-col>
                <el-col :lg="6" :md="6" :xs="6" :sm="6" :xl="6">
                  <el-form-item label="Month">
                    <el-checkbox v-model="monthChecked"></el-checkbox>
                  </el-form-item>
                </el-col>
                <el-col :lg="6" :md="6" :xs="6" :sm="6" :xl="6">
                  <el-form-item label="Date">
                    <el-checkbox v-model="dateChecked"></el-checkbox>
                  </el-form-item>
                </el-col>
                <el-col :lg="6" :md="6" :xs="6" :sm="6" :xl="6">
                  <el-form-item label="Hour">
                    <el-checkbox v-model="hourChecked"></el-checkbox>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <label class="labelName" style="margin-right:60px;">Date</label>
                <el-form-item>
                  <el-date-picker
                    v-model="startToEndDate"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    type="daterange"
                    style="width:260px;"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions"
                  ></el-date-picker>
                </el-form-item>
              </el-row>
              <el-row>
                <el-col :lg="3" :md="3" :xs="3" :sm="3" :xl="3">
                  <label class="labelName" style="margin-right:40px;">Sort by</label>
                </el-col>
                <el-col :lg="20" :md="20">
                  <el-form-item>
                    <el-radio-group v-model="eventReport.sorting">
                      <el-radio
                        style="margin-right:140px;"
                        v-for="item in radioOptions"
                        :key="item"
                        :label="item"
                        >{{ item }}</el-radio
                      >
                    </el-radio-group>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :lg="3" :md="20" :offset="1">
                  <el-form-item>
                    <el-button type="primary" @click="searchListClick(1)">In Browser</el-button>
                  </el-form-item>
                </el-col>
                <el-col :lg="3" :md="20">
                  <el-form-item>
                    <el-button type="primary" @click="exportExcelFileClick"
                      >Download Excel</el-button
                    >
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-card>
        </el-col>
      </el-row>
      <el-card shadow="hover">
        <el-table
          v-loading="listLoading"
          ref="tableRef"
          style="width:100%;"
          height="70vmin"
          stripe
          border
          :data="eventReportList"
          highlight-current-row
        >
          <el-table-column
            label="Date"
            min-width="100"
            prop="date"
            align="center"
            :fixed="fullWidth <= 768 ? false : 'left'"
            key="date"
          ></el-table-column>

          <el-table-column
            label="Affiliate ID"
            min-width="100"
            align="center"
            :fixed="fullWidth <= 768 ? false : 'left'"
            v-if="affiliateChecked"
            key="affiliateId"
          >
            <template slot-scope="scope">
              <span class="cor337ab7" @click="affiliatetailClick(scope.row)">{{
                scope.row.affiliateId
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="Affiliate Name"
            min-width="140"
            :fixed="fullWidth <= 768 ? false : 'left'"
            v-if="affiliateChecked"
            align="center"
            key="affiliateName"
          >
            <template slot-scope="scope">
              <span class="cor337ab7" @click="affiliatetailClick(scope.row)">{{
                scope.row.affiliateName
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="Pkg"
            min-width="140"
            prop="pkg"
            :fixed="fullWidth <= 768 ? false : 'left'"
            v-if="packageChecked"
            align="center"
            key="pkg"
          >
          </el-table-column>
          <el-table-column
            label="Aff Sub"
            min-width="140"
            prop="affiliateSub"
            :fixed="fullWidth <= 768 ? false : 'left'"
            v-if="affiliateSubChecked"
            align="center"
            key="affiliateSub"
          >
          </el-table-column>
          <el-table-column
            label="Source ID"
            min-width="100"
            key="sourceId"
            v-if="sourceChecked"
            align="center"
          >
            <template slot-scope="scope">
              <span class="cor337ab7" @click="sourcedetailClick(scope.row)">{{
                scope.row.sourceId
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="Source Name"
            min-width="110"
            prop="sourceName"
            v-if="sourceChecked"
            align="center"
            key="sourceName"
          >
            <template slot-scope="scope">
              <span class="cor337ab7" @click="sourcedetailClick(scope.row)">{{
                scope.row.sourceName
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="Offer ID"
            min-width="100"
            v-if="offerChecked"
            key="offerId"
            align="center"
          >
            <template slot-scope="scope">
              <span class="cor337ab7" @click="offerdetailClick(scope.row)">{{
                scope.row.offerId
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Offer Name" width="100" v-if="offerChecked" align="center">
            <template slot-scope="scope">
              <span class="cor337ab7" @click="offerdetailClick(scope.row)">{{
                scope.row.offerName
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="Offer Status"
            min-width="110"
            prop="offerStatus"
            v-if="offerChecked"
            align="center"
            key="status"
          ></el-table-column>
          <el-table-column
            label="Countries"
            min-width="140"
            prop="countries"
            v-if="countriesChecked"
            align="center"
            key="countries"
          >
          </el-table-column>
          <el-table-column
            label="EventName"
            min-width="100"
            prop="eventName"
            align="center"
            key="eventName"
          ></el-table-column>
          <el-table-column
            label="Event Conversions"
            min-width="125"
            prop="eventConversions"
            align="center"
            key="eventConversions"
          ></el-table-column>
          <el-table-column
            label="Conversions"
            min-width="110"
            prop="conversions"
            align="center"
            key="conversions"
          ></el-table-column>
          <el-table-column
            label="Aff Conversions"
            align="center"
            min-width="140"
            prop="affConversions"
            key="affConversions"
          >
          </el-table-column>
          <el-table-column
            label="Event Percent"
            min-width="120"
            key="totalClicks"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <span
                v-html="
                  showReportComparingItem(
                    filterNull(scope.row.eventConversions),
                    filterNull(scope.row.conversions)
                  )
                "
              ></span>
            </template>
          </el-table-column>
        </el-table>
        <pagination
          class="block pagePanel"
          :pageTotal="total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :pageSize="pageParam.pageSize"
          :currentPage="pageParam.page"
        ></pagination>
      </el-card>
    </div>
  </div>
</template>
<script>
  import { api, getEventReport } from 'api/report';
  import Pagination from '@/components/pagination';
  import GroupSelect from '@/components/GroupSelect';
  import { cloneDeep } from 'lodash-es';
  import dateUtils from '@/utils/dateutils.js';
  import qs from 'querystring';
  import { myMixin } from '@/mixins/mixins.js';
  import optionData from '@/assets/js/optionData';
  import { filterObject } from 'utils/utils';
  import { mapState, mapActions } from 'vuex';

  export default {
    mixins: [myMixin],
    components: {
      Pagination,
      GroupSelect,
    },
    data() {
      return {
        pageParam: {
          page: 1,
          pageSize: 20,
        },
        dataParam: null,
        offerChecked: false,
        affiliateChecked: true,
        affiliateSubChecked: false,
        sourceChecked: false,
        fullWidth: document.documentElement.clientWidth,
        monthChecked: false,
        dateChecked: true,
        hourChecked: true,
        startToEndDate: '',
        listLoading: false,
        total: 0,
        radioOptions: ['conversions', 'eventConversions'],
        summariesMap: {},
        eventReportList: [],
        timezoneOption: optionData.timezoneOption,
        categoryOption: optionData.categoryOption,
        eventReport: {
          eventName: '',
          offerIds: null,
          affiliateIds: null,
          affiliateSub: '',
          columns: ['event_name'],
          fromDate: null,
          toDate: null,
          sorting: 'conversions',
          sourceIds: null,
          timezone: 0,
          pkgList: '',
          countries: '',
        },
        packageChecked: false,
        countriesChecked: false,
        sourceIdArray: [],
        affiliateIdArray: [],
      };
    },
    mounted() {
      //默认选择
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() + 3600 * 1000 * 24);
      this.startToEndDate = [];
      this.startToEndDate[0] = dateUtils.date2StrYMD(end);
      this.startToEndDate[1] = dateUtils.date2StrYMD(start);

      this.getAffiliateList();
      this.getSourceIdsList();
    },
    computed: {
      ...mapState('affiliate', {
        affiliatesGroupList: (state) => state.affiliatesGroupList,
        affiliateLoading: (state) => state.affiliateLoading,
      }),
      ...mapState('source', {
        sourcesList: (state) => state.sourcesList,
        sourceLoading: (state) => state.sourceLoading,
      }),
      listenChange() {
        const {
          affiliateChecked,
          sourceChecked,
          offerChecked,
          affiliateSubChecked,
          packageChecked,
          countriesChecked,
        } = this;
        return {
          affiliateChecked,
          sourceChecked,
          offerChecked,
          affiliateSubChecked,
          packageChecked,
          countriesChecked,
        };
      },
    },
    watch: {
      listenChange: function() {
        this.$nextTick(() => {
          this.$refs.tableRef.doLayout();
        });
      },
    },
    methods: {
      ...mapActions('affiliate', ['getAffiliateList']),
      ...mapActions('source', ['getSourceIdsList']),
      searchListClick(curPage) {
        if (curPage) {
          this.pageParam.page = curPage;
        }
        let param = {
          ...this.eventReport,
          ...this.pageParam,
        };
        if (Array.isArray(param.sourceIds) && param.sourceIds.length > 0) {
          param.sourceIds = param.sourceIds.join(',');
        } else {
          delete param.sourceIds;
        }
        if (Array.isArray(param.affiliateIds) && param.affiliateIds.length > 0) {
          param.affiliateIds = param.affiliateIds.join(',');
        } else {
          delete param.affiliateIds;
        }

        if (this.offerChecked == true && param.columns.indexOf('offer_id') == -1) {
          param.columns.push('offer_id');
        } else if (this.offerChecked == false && param.columns.indexOf('offer_id') !== -1) {
          const offerIdIndex = param.columns.findIndex((item) => {
            if (item == 'offer_id') {
              return true;
            }
          });
          param.columns.splice(offerIdIndex, 1);
        }

        if (this.affiliateChecked == true && param.columns.indexOf('affiliate_id') == -1) {
          param.columns.push('affiliate_id');
        } else if (this.affiliateChecked == false && param.columns.indexOf('affiliate_id') !== -1) {
          const affiliateIndex = param.columns.findIndex((item) => {
            if (item == 'affiliate_id') {
              return true;
            }
          });
          param.columns.splice(affiliateIndex, 1);
        }

        if (this.sourceChecked == true && param.columns.indexOf('source_id') == -1) {
          param.columns.push('source_id');
        } else if (this.sourceChecked == false && param.columns.indexOf('source_id') !== -1) {
          const sourceIdIndex = param.columns.findIndex((item) => {
            if (item == 'source_id') {
              return true;
            }
          });
          param.columns.splice(sourceIdIndex, 1);
        }

        if (this.packageChecked == true && param.columns.indexOf('pkg') == -1) {
          param.columns.push('pkg');
        } else if (this.packageChecked == false && param.columns.indexOf('pkg') !== -1) {
          const pkgIndex = param.columns.findIndex((item) => {
            if (item == 'pkg') {
              return true;
            }
          });
          param.columns.splice(pkgIndex, 1);
        }

        if (this.countriesChecked == true && param.columns.indexOf('country') == -1) {
          param.columns.push('country');
        } else if (this.countriesChecked == false && param.columns.indexOf('country') !== -1) {
          const countryIndex = param.columns.findIndex((item) => {
            if (item == 'country') {
              return true;
            }
          });
          param.columns.splice(countryIndex, 1);
        }
        if (this.affiliateSubChecked == true && param.columns.indexOf('affiliate_sub') == -1) {
          param.columns.push('affiliate_sub');
        } else if (
          this.affiliateSubChecked == false &&
          param.columns.indexOf('affiliate_sub') !== -1
        ) {
          const affSubIndex = param.columns.findIndex((item) => {
            if (item == 'affiliate_sub') {
              return true;
            }
          });
          param.columns.splice(affSubIndex, 1);
        }
        if (this.monthChecked == true && param.columns.indexOf('month') == -1) {
          param.columns.push('month');
        } else if (this.monthChecked == false && param.columns.indexOf('month') !== -1) {
          const monthIndex = param.columns.findIndex((item) => {
            if (item == 'month') {
              return true;
            }
          });
          param.columns.splice(monthIndex, 1);
        }

        if (this.dateChecked == true && param.columns.indexOf('date') == -1) {
          param.columns.push('date');
        } else if (this.dateChecked == false && param.columns.indexOf('date') !== -1) {
          const dateIndex = param.columns.findIndex((item) => {
            if (item == 'date') {
              return true;
            }
          });
          param.columns.splice(dateIndex, 1);
        }

        if (this.hourChecked == true && param.columns.indexOf('hour') == -1) {
          param.columns.push('hour');
        } else if (this.hourChecked == false && param.columns.indexOf('hour') !== -1) {
          const hourIndex = param.columns.findIndex((item) => {
            if (item == 'hour') {
              return true;
            }
          });
          param.columns.splice(hourIndex, 1);
        }

        if (this.startToEndDate.length === 2) {
          let startDate = this.startToEndDate[0];
          let endDate = this.startToEndDate[1];
          param.fromDate = startDate;
          param.toDate = endDate;
        }
        this.pageParam.page = 1;

        const dataParam = cloneDeep(param);
        if (Array.isArray(dataParam.columns)) {
          dataParam.columns = dataParam.columns.join(',');
        }
        this.dataParam = dataParam;

        this.eventReportApi();
      },
      eventReportApi() {
        this.listLoading = true;
        let param = {
          ...this.dataParam,
          ...this.pageParam,
        };
        param = filterObject(param);
        getEventReport(param)
          .then((response) => {
            this.listLoading = false;
            if (response.code === 200) {
              const result = response.result || {};
              this.eventReportList = result?.dataList ?? [];
              this.summariesMap = result?.total;
              this.total = response.total;

              if (this.summariesMap && this.eventReportList.length > 0) {
                const totalObject = new Object();
                totalObject.date = 'Total';
                totalObject.conversions = this.summariesMap.conversions;
                totalObject.eventConversions = this.summariesMap.eventConversions;
                totalObject.affConversions = this.summariesMap.affConversions;
                this.eventReportList.push(totalObject);
              }
            } else {
              this.eventReportList = [];
              this.summariesMap = {};
              this.total = null;
              this.$message.error(response.message);
            }
          })
          .catch((error) => {
            this.listLoading = false;
            this.$message.error(error);
            this.eventReportList = [];
          });
      },
      exportExcelFileClick() {
        if (this.sourceIdArray != [] && this.sourceIdArray.length > 0) {
          this.eventReport.sourceIds = this.sourceIdArray;
        }

        if (this.affiliateIdArray != [] && this.affiliateIdArray.length > 0) {
          this.eventReport.affiliateIds = this.affiliateIdArray;
        }

        let param = {};
        let columns = ['event_name'];

        if (this.affiliateChecked == true && columns.indexOf('affiliate_id') == -1) {
          columns.push('affiliate_id');
        } else if (this.affiliateChecked == false && columns.indexOf('affiliate_id') !== -1) {
          const affiliateIndex = columns.findIndex((item) => {
            if (item == 'affiliate_id') {
              return true;
            }
          });
          columns.splice(affiliateIndex, 1);
        }
        if (this.packageChecked == true && columns.indexOf('pkg') == -1) {
          columns.push('pkg');
        } else if (this.packageChecked == false && columns.indexOf('pkg') !== -1) {
          const pkgIndex = columns.findIndex((item) => {
            if (item == 'pkg') {
              return true;
            }
          });
          columns.splice(pkgIndex, 1);
        }
        if (this.countriesChecked == true && columns.indexOf('country') == -1) {
          columns.push('country');
        } else if (this.countriesChecked == false && columns.indexOf('country') !== -1) {
          const countryIndex = columns.findIndex((item) => {
            if (item == 'country') {
              return true;
            }
          });
          columns.splice(countryIndex, 1);
        }
        if (this.affiliateSubChecked == true && columns.indexOf('affiliate_sub') == -1) {
          columns.push('affiliate_sub');
        } else if (this.affiliateSubChecked == false && columns.indexOf('affiliate_sub') !== -1) {
          const affSubIndex = columns.findIndex((item) => {
            if (item == 'affiliate_sub') {
              return true;
            }
          });
          columns.splice(affSubIndex, 1);
        }
        if (this.sourceChecked == true && columns.indexOf('source_id') == -1) {
          columns.push('source_id');
        } else if (this.sourceChecked == false && columns.indexOf('source_id') !== -1) {
          const sourceIdIndex = columns.findIndex((item) => {
            if (item == 'source_id') {
              return true;
            }
          });
          columns.splice(sourceIdIndex, 1);
        }

        if (this.offerChecked == true && columns.indexOf('offer_id') == -1) {
          columns.push('offer_id');
        } else if (this.offerChecked == false && columns.indexOf('offer_id') !== -1) {
          const offerIdIndex = columns.findIndex((item) => {
            if (item == 'offer_id') {
              return true;
            }
          });
          columns.splice(offerIdIndex, 1);
        }

        if (this.monthChecked == true && columns.indexOf('month') == -1) {
          columns.push('month');
        } else if (this.monthChecked == false && columns.indexOf('month') !== -1) {
          const monthIndex = columns.findIndex((item) => {
            if (item == 'month') {
              return true;
            }
          });
          columns.splice(monthIndex, 1);
        }

        if (this.dateChecked == true && columns.indexOf('date') == -1) {
          columns.push('date');
        } else if (this.dateChecked == false && columns.indexOf('date') !== -1) {
          const dateIndex = columns.findIndex((item) => {
            if (item == 'date') {
              return true;
            }
          });
          columns.splice(dateIndex, 1);
        }

        if (this.hourChecked == true && columns.indexOf('hour') == -1) {
          columns.push('hour');
        } else if (this.hourChecked == false && columns.indexOf('hour') !== -1) {
          const hourIndex = columns.findIndex((item) => {
            if (item == 'hour') {
              return true;
            }
          });
          columns.splice(hourIndex, 1);
        }

        param['fromDate'] = this.startToEndDate[0];
        param['toDate'] = this.startToEndDate[1];
        if (this.eventReport.timezone != null) {
          param['timezone'] = this.eventReport.timezone;
        }

        if (this.eventReport.sourceIds != null && this.eventReport.sourceIds.length > 0) {
          param['sourceIds'] = this.eventReport.sourceIds + '';
        }
        if (this.eventReport.affiliateIds != null) {
          param['affiliateIds'] = this.eventReport.affiliateIds + '';
        }
        if (this.eventReport.offerIds != null) {
          param['offerIds'] = this.eventReport.offerIds;
        }
        if (columns != null) {
          param['columns'] = columns.join(',');
        }
        param = filterObject(param);
        let strParam = qs.stringify(param);

        window.open(`${api.DOWNLOAD_EVENT_REPORT}?${strParam}`, '_blank');
      },
      handleSizeChange(size) {
        this.pageParam.pageSize = size;
        this.handleCurrentChange(1);
      },
      handleCurrentChange(currentPage) {
        this.pageParam.page = currentPage;
        this.eventReportApi();
      },
      affiliatetailClick(row) {
        const { href } = this.$router.resolve({
          path: '/affiliate/detail',
          query: {
            affiliateId: row.affiliateId,
          },
        });
        window.open(href, '_blank');
      },
      sourcedetailClick(row) {
        const { href } = this.$router.resolve({
          path: '/source/detail',
          query: {
            sourceId: row.sourceId,
          },
        });
        window.open(href, '_blank');
      },
      offerdetailClick(row) {
        const { href } = this.$router.resolve({
          path: '/offer/detail',
          query: {
            offerId: row.offerId,
          },
        });
        window.open(href, '_blank');
      },
      filterNull(val) {
        return val == null ? 0 : val;
      },
      showReportComparingItem(cur, last) {
        return 0 == last ? '-' : new Number((cur / last) * 100).toFixed(2) + '%';
      },
    },
  };
</script>
<style lang="scss" scoped>
  .black-dialog {
    width: 400px;
    p {
      line-height: 30px;
    }
    input {
      width: 300px;
    }
  }
</style>
<style lang="scss"></style>
